import axios from '@/services/useAxios';

export default function () {
    const uploadTempFile = async (file) => {
        const formData = new FormData(); // pass data as a form
        formData.append('file', file);

        const res = await axios.post('temp-file', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data;
    };

    return { uploadTempFile };
}
