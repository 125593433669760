<template>
    <div class="dragNdrop" v-bind="getRootProps()">
        <input v-bind="getInputProps()" />
        <div v-if="isDragActive" class="text-center">
            <i class="far fa-cloud-upload"></i>
            <span>Suelta tus archivos aqui ...</span>
        </div>
        <div v-else class="text-center">
            <span v-if="fileName">{{ fileName }}</span>
            <span v-else>Arrastra tus archivos aqui! o click para seleccionar</span>
        </div>
    </div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue';
import { useDropzone } from 'vue3-dropzone';
import useTempfile from '@/services/useTempfile';

export default {
    props: {
        modelValue: String,
        file: [Object, String],
    },
    setup(props, { emit }) {
        const internalFile = reactive({});
        const { uploadTempFile } = useTempfile();
        const onDrop = async (acceptFiles, rejectReasons) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const acceptfile of acceptFiles) {
                // eslint-disable-next-line no-await-in-loop
                const fileUploaded = await uploadTempFile(acceptfile);
                // eslint-disable-next-line no-return-assign
                Object.keys(fileUploaded).map((key) => (internalFile[key] = fileUploaded[key]));

                emit('update:modelValue', internalFile.id);
            }
            if (rejectReasons.length) console.log(rejectReasons);
        };
        const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop, maxFiles: 1 });

        onMounted(() => {
            console.log(props.file);
            if (props.file) {
                console.log(typeof props.file);
                if (props.file && typeof props.file === 'object') {
                    // eslint-disable-next-line no-return-assign
                    Object.keys(props.file).map((key) => (internalFile[key] = props.file[key]));
                }
            }
        });

        const fileName = computed(() => {
            console.log('internalFile.name', internalFile.name);
            console.log('props.file', props.file);
            console.log('props.file?.name', props.file?.name);
            if (internalFile.name) {
                return internalFile.name;
            }
            if (typeof props.file === 'string') {
                return props.file;
            }
            return props.file?.name;
        });

        return {
            getRootProps,
            getInputProps,
            ...rest,
            internalFile,
            fileName,
        };
    },
};
</script>

<style>
.form-control {
    border-color: #efefef;
    background-color: #fff;
    font-size: 14px;
    color: #212529;
}
</style>
